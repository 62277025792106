import React, { Component } from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';


//Images

import pic1 from './../../images/about/pic1.jpg';
import pic2 from './../../images/about/pic2.jpg';




class AllBlogs extends Component {
    render() {
        return (
            <>
                <Header />
                <section className='banner-lti-air'>
                              <div className='container'>
                                  <div className='row'>
                                      <div className='col-md-12'>
                                      <h2 className='banner-heading'>
                       Our <span> Blog</span>
                         </h2>
                                      </div>
                                  </div>
                              </div>
                         
                     </section>
                     <section className='blogsection'>
                         <div className='container'>
                             <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                            <div className="dlab-post-media dlab-img-effect ">
                            <a href="/HowtoBeASuccessfulTruckingFleetDispatcher">
                            <img src={pic1} data-tilt alt="" />
                            </a> 
                            </div>
                            <div class="dlab-info p-a20 border-1">
                            <div class="dlab-post-title "><h4 class="post-title"><a href="/HowtoBeASuccessfulTruckingFleetDispatcher">How to Be A Successful Trucking Fleet Dispatcher?</a></h4></div>
                            <div class="dlab-post-meta"><ul class="d-flex align-items-center">
                                <li class="post-date"> <i class="fa fa-calendar"></i><strong>10 Aug</strong> <span> 2016</span> </li>
                                <li class="post-author"><i class="fa fa-user"></i>By <a href="#">Jone</a> </li>
                                <li class="post-comment"><i class="fa fa-comments"></i> <a href="#">5k</a> </li>
                                </ul>
                                </div>
                                <div class="dlab-post-text"><p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.</p>
                                </div>
                                <div class="dlab-post-readmore blog-share">
                                    <a title="READ MORE" rel="bookmark" class="site-button outline outline-1" href="#">READ MORE<i class="fa fa-long-arrow-right ml-1"></i></a></div>
                            </div>
                            
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                            <div className="dlab-post-media dlab-img-effect ">
                            <a href="/HowtoBeASuccessfulTruckingFleetDispatcher">
                            <img src={pic2} data-tilt alt="" />
                            </a> 
                            </div>
                            <div class="dlab-info p-a20 border-1">
                            <div class="dlab-post-title "><h4 class="post-title"><a href="/HowtoBeASuccessfulTruckingFleetDispatcher">What Insurance Covers Do Truck Drivers Need</a></h4></div>
                            <div class="dlab-post-meta"><ul class="d-flex align-items-center">
                                <li class="post-date"> <i class="fa fa-calendar"></i><strong>10 Aug</strong> <span> 2016</span> </li>
                                <li class="post-author"><i class="fa fa-user"></i>By <a href="#">Jone</a> </li>
                                <li class="post-comment"><i class="fa fa-comments"></i> <a href="#">5k</a> </li>
                                </ul>
                                </div>
                                <div class="dlab-post-text"><p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.</p>
                                </div>
                                <div class="dlab-post-readmore blog-share">
                                    <a title="READ MORE" rel="bookmark" class="site-button outline outline-1" href="#">READ MORE<i class="fa fa-long-arrow-right ml-1"></i></a></div>
                            </div>
                            
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                            <div className="dlab-post-media dlab-img-effect ">
                            <a href="/HowtoBeASuccessfulTruckingFleetDispatcher">
                            <img src={pic1} data-tilt alt="" />
                            </a> 
                            </div>
                            <div class="dlab-info p-a20 border-1">
                            <div class="dlab-post-title "><h4 class="post-title"><a href="/HowtoBeASuccessfulTruckingFleetDispatcher">How Long Does It Take To Get A CDL For Truck Driver</a></h4></div>
                            <div class="dlab-post-meta"><ul class="d-flex align-items-center">
                                <li class="post-date"> <i class="fa fa-calendar"></i><strong>10 Aug</strong> <span> 2016</span> </li>
                                <li class="post-author"><i class="fa fa-user"></i>By <a href="#">Jone</a> </li>
                                <li class="post-comment"><i class="fa fa-comments"></i> <a href="#">5k</a> </li>
                                </ul>
                                </div>
                                <div class="dlab-post-text"><p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.</p>
                                </div>
                                <div class="dlab-post-readmore blog-share">
                                    <a title="READ MORE" rel="bookmark" class="site-button outline outline-1" href="#">READ MORE<i class="fa fa-long-arrow-right ml-1"></i></a></div>
                            </div>
                            
                            </div>
                             </div>

                         </div>
                     </section>
                <div className="page-content bg-white">
               

         
                </div>
                <Footer />
            </>
        )
    }
}

export default AllBlogs;