import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox';

//Images
import pic1 from './../../images/blog/recent-blog/pic1.jpg';
import pic2 from './../../images/blog/recent-blog/pic2.jpg';


const widgetPost =  [
	{image: pic1, },{image: pic2, },
];



class Sidebar extends Component{
	render(){
		return(
			<>
				<aside className="side-bar">
					
					<div className="widget recent-posts-entry">
						<h5 className="widget-title style-1">Recent Posts</h5>
						<div className="widget-post-bx">
							{widgetPost.map((item,index)=>(
								<div className="widget-post clearfix" key={index}>
									<div className="dlab-post-media"> <img src={item.image} width="200" height="143" alt="" /> </div>
									<div className="dlab-post-info">
										<div className="dlab-post-header">
											<h6 className="post-title"><Link to={"#"}>Title of first blog post entry</Link></h6>
										</div>
										<div className="dlab-post-meta">
											<ul>
												<li className="post-author">By Jone</li>
												<li className="post-comment"><i className="fa fa-comments"></i> 28</li>
											</ul>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				
					<div className="widget widget_archive">
						<h5 className="widget-title style-1">Categories List</h5>
						<ul>
							<li><Link to={"#"}>aciform</Link></li>
							<li><Link to={"#"}>championship</Link></li>
							<li><Link to={"#"}>chastening</Link></li>
							<li><Link to={"#"}>clerkship</Link></li>
							<li><Link to={"#"}>disinclination</Link></li>
						</ul>
					</div>
					
					<div className="widget widget_tag_cloud radius">
						<h5 className="widget-title style-1">Tags</h5>
						<div className="tagcloud">
							<Link to={"#"} className="mr-1">Design</Link>
							<Link to={"#"} className="mr-1">User interface</Link>
							<Link to={"#"} className="mr-1">SEO</Link>
							<Link to={"#"} className="mr-1">WordPress</Link>
							<Link to={"#"} className="mr-1">Development</Link>
							<Link to={"#"} className="mr-1">Joomla</Link>
							<Link to={"#"} className="mr-1">Design</Link>
							<Link to={"#"} className="mr-1">User interface</Link>
							<Link to={"#"} className="mr-1">SEO</Link>
							<Link to={"#"} className="mr-1">WordPress</Link>
							<Link to={"#"} className="mr-1">Development</Link>
							<Link to={"#"} className="mr-1">Joomla</Link>
							<Link to={"#"} className="mr-1">Design</Link>
							<Link to={"#"} className="mr-1">User interface</Link>
							<Link to={"#"} className="mr-1">SEO</Link>
							<Link to={"#"} className="mr-1">WordPress</Link>
							<Link to={"#"} className="mr-1">Development</Link>
							<Link to={"#"} className="mr-1">Joomla</Link>
						</div>
					</div>
				</aside>
			</>
		)
	}
}
export default Sidebar;