import React,{Component} from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import Index5Carousel from './../Element/Index5Carousel';


//Images


import aboutimg from './../../images/about/aboutimg.jpg';
import SUPPLY from './../../images/about/SUPPLY.jpg';
import INTERMODALSHIPPING from './../../images/about/INTERMODALSHIPPING.jpg';





class About extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper">
				<Header />
                 <div className="page-content bg-white">
                 <section className='banner-lti-About'>
                              <div className='container'>
                                  <div className='row'>
                                      <div className='col-md-12'>
                                      <h2 className='banner-heading'>
                      About <span> ALS</span> 
                         </h2>
                                      </div>
                                  </div>
                              </div>
                         
                     </section>
                 

 {/* aboutus */}
                 <section className='aboutfirst'>
                          <div className='container'>
                        <div  className='row'>
                         <div className='col-md-7' id='fulfilling'>
                        <h2>Transforming The Future Of Logistics With Our Global Freight Transportation Solutions</h2>
                           <p className='problems'> ALS is a leading logistics provider committed to fulfilling our customer’s needs for freight transportation. 
                                 With roots in land, sea, and air transportation, our company was founded with the mission to provide flexible solutions 
                                 to complex logistics problems. We do not just move cargo. Instead, we drive businesses forward with expertise as well as integrity.</p>
                            <p>Armed with a wealth of knowledge and experience, our professionals deliver smarter logistics solutions that drive success for our shippers and carriers. 
                              With a network of more than 15000+ carriers, ALS provides multimodal freight transportation services for shippers of any size. </p>
                              <br></br>
                              <a href='#' className="site-button">REQUEST A QUOTE </a>
                            </div>
                                <div className='col-md-5' >
                                 <img src={aboutimg} data-tilt alt="" />
                                     </div>
                                     </div>

                                      </div>

                                    </section>
                                    {/* aboutus */}
 {/* thirdsection about  */}
 <section className='new-about'>
   <div className='container-fluid'>
     <div className='row'>
       <div className='col-md-12 	col-xl-8 col-lg-12' id='new-about'>
       
        <h2>Our team's experience and successful actions are the guarantee that your MC gets the desired results</h2>
        <p>ALS is one of the leading intermodal freight transportation companies offering door-to-door shipping services. Our intermodal shipping services integrate the strengths of the best modes to fuel your supply chain while offering high visibility.</p>

<p>Leveraging cutting-edge technology that provides 24/7 shipment visibility, our intermodal specialists make sure that your freight reaches its destination on time and in a safe manner. We invite you to utilize our experience, knowledge, and expertise to meet your shipping needs.</p>
        </div>
        <div className='col-md-12 col-xl-4 col-lg-12' id='new-about2'>
        <div className="row did-know-box show-on-scroll inline-photo is-visible">
                <div className="col-md-0 col-xl-4">
                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                </div>
                <div className="col-md-9 p-0 heading">
                    <h3><span>200+</span>Active Trucks</h3>
                  <p>Take a trivial example, which seds ever undertakes laborious.</p>
                 
                </div>
              </div>
              <div className="row did-know-box show-on-scroll inline-photo">
                <div className="col-md-0 col-xl-4">
                <i class="fa fa-user-o" aria-hidden="true"></i>
                </div>
                <div className="col-md-9 p-0 heading">
                  <h3><span>70+</span>Employees</h3>
                  <p>Take a trivial example, which seds ever undertakes laborious.</p>
                 
                </div>
              </div>
          
          </div>
       </div>
       </div>
 </section>
 
  {/* thirdsection about  */}
  

   {/* Fourthsection about  */}
   <section className='Fourthsection'>
     <div className='container'>
       <div className='row'>
         <div className='col-md-6'>
         <div transition-style="in:wipe:right" id='Fourt'>
         <img src={SUPPLY} data-tilt alt="" />
</div>

         </div>
         <div className='col-md-6' id='Fourt1'>
         <h2>We Guarantee Quick And Safe Delivery Of Your Freight</h2>
         <p className='Drivn'>Driven by excellence, we can create a personalized solution that fits your business. Whether you want to reduce your 
           coast or streamline your supply chain, we can help you get there with ease.</p>
           <h4>Safety and Compliance</h4>
           <p>With ALS, you can be sure that your shipment will be handled in the best possible way. We offer a solution designed to meet your unique needs.</p>
             <h4>Quick Delivery</h4>
             <p>Our professionals will take care of the paperwork and other requirements to ensure quick delivery of your cargo.</p>
             <h4>Real-Time Tracking</h4>
             <p>From the moment your shipment is collected until the final delivery, we will provide you with real-time updates about the location of your freight.</p>
                    
         </div>
       </div>
     </div>

   </section>
    {/* Fourthsection about  */}
    <section className='blacksection'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='for-mobile'>
                                            <h2><span>WE’RE HERE TO </span>RESOLVE YOUR BIGGEST<span> LOGISTICS CHALLENGES</span></h2>
                                        </div>
                                    <h2 className="pt-plus-cd-headline letters scale" id='pulse'>
                                        <span>We’re here to </span>
                                        <span className="cd-words-wrapper style-6" style={{width: '1161.44px'}}>
                                        <b className="is-visible " style={{opacity: 1}}>
                                        <div class="waviy">
                                            <span className='s1'>r</span>
                                            <span  className='s2'>e</span>
                                            <span  className='s3'>s</span>
                                            <span  className='s4'>o</span>
                                            <span  className='s5'>l</span>
                                            <span  className='s6'>v</span>
                                            <span  className='s7'>e</span>
                                            </div>
                                            
                                            <div class="waviy1">
                                            <span  className='s8'></span>
                                            <span  className='s9'>y</span>
                                            <span  className='s10'>o</span>
                                            <span  className='s11'>u</span>
                                            <span  className='s12'>r</span>
                                            </div>
                                            <br></br>
                                            <div class="waviy2">
                                            <span  className='s13'>b</span>
                                            <span  className='s14'>i</span>
                                            <span  className='s15'>g</span>
                                            <span  className='s16'>g</span>
                                            <span  className='s17'>e</span>
                                            <span  className='s18'>s</span>
                                            <span  className='s19'>t</span>
                                            
                                                                                    
                                             </div>
                                        </b></span><span>  logistics challenges </span></h2>
                                        <br></br>
                                        <a href='#' className="site-button">REQUEST A QUOTE </a>
                                        
                                    </div>

                                </div>
                            </div>

                        </section>
   
                        <section className='int-third'>
                <div className='container'>
                    <div className='row'>
                         <div className='col-md-6' id='Transportation'>
                        <img src={INTERMODALSHIPPING} data-tilt alt=""  className='.slideInUp' / >
                        </div>
                        <div className='col-md-6' id='Transportation2'>
                   <h2>Intermodal Transportation Services That Increase Your Bottom Line</h2>
                   <div className='leading'>
                   <p>ALS is one of the leading intermodal freight transportation companies offering door-to-door shipping services. Our intermodal shipping services integrate the strengths of the best modes to fuel your supply chain while offering high visibility.</p>

<p>Leveraging cutting-edge technology that provides 24/7 shipment visibility, our intermodal specialists make sure that your freight reaches its destination on time and in a safe manner. We invite you to utilize our experience, knowledge, and expertise to meet your shipping needs.</p>
                   
            </div> 
                   </div>
                    </div>
                </div>
            </section>
 

    {/* Fivthsection about  */}
   

      {/* Fivthsection about  */}
    
  				</div>	
				<Footer />
				
			</div>		
		)
	}
}
export default About;