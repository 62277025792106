import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ThemeButton from './Element/ThemeButton/ThemeButton';
import Error403 from './Pages/Error/Error403';
import Error404 from './Pages/Error/Error404';
import Error405 from './Pages/Error/Error405';
import UnderMaintenance from './Pages/UnderMaintenance';
import BlogDetails from './Pages/BlogDetail/BlogDetails';

import Faqs from './Pages/Faqs';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Contact from './Pages/Contact';



import ShortTabs from './Pages/ShortCode/ShortTabs';
import ShortTestimonial from './Pages/ShortCode/ShortTestimonial';
import ShortForm from './Pages/ShortCode/ShortForm';

import ScrollToTop from './Element/ScrollToTop';
import Myhome from './Pages/Myhome';
import About from './Pages/About';
import AirFreight from './Pages/AirFreight';
import Intermodalshipping from './Pages/Intermodalshipping';
import Flatbedfreight from './Pages/Flatbedfreight';
import Lessthancontainerload from './Pages/Lessthancontainerload';
import Lessthantruckloadltlshipping from './Pages/Lessthantruckloadltlshipping';
import Fulltruckloadshipping from './Pages/Fulltruckloadshipping';
import Carrier from './Pages/Carrier';
import BlogClassicGrid from './Pages/BlogClassic/BlogClassicGrid';
import BlogClassicLeftSidebar from './Pages/BlogClassic/BlogClassicLeftSidebar';
import BlogClassicRightSidebar from './Pages/BlogClassic/BlogClassicRightSidebar';
import HowtoBeASuccessfulTruckingFleetDispatcher from './Pages/HowtoBeASuccessfulTruckingFleetDispatcher';
import AllBlogs from './Pages/AllBlogs';
import Temperaturecontrolledshipping from './Pages/Temperaturecontrolledshipping';
import Thankyou from './Pages/Thankyou';


class Markup extends Component{
	render(){
		return(
			<BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                    <Route path='/Thankyou' exact component={Thankyou} />
                                       <Route path='/Temperaturecontrolledshipping' exact component={Temperaturecontrolledshipping} />
                        <Route path= '/HowtoBeASuccessfulTruckingFleetDispatcher' exact component={HowtoBeASuccessfulTruckingFleetDispatcher} />
                    <Route path= '/AllBlogs' exact component={AllBlogs} />
                    <Route path='/blog-details' exact component={BlogDetails} />
                    <Route path='/BlogClassicGrid' exact component={BlogClassicGrid}/>
                    <Route path='/BlogClassicLeftSidebar' exact component={BlogClassicLeftSidebar}/>
                    <Route path='/blog-classic-right-sidebar' exact component={BlogClassicRightSidebar} />
                      <Route path='/Carrier' exact component={Carrier}/>
                         <Route path='/Fulltruckloadshipping' exact component={Fulltruckloadshipping} />
                        <Route path='/Lessthantruckloadltlshipping'exact component={Lessthantruckloadltlshipping} />
                        <Route path='/Lessthancontainerload' exact component={Lessthancontainerload} />
                        <Route path= '/Flatbedfreight'exact component={Flatbedfreight} />
                        <Route path='/' exact component={Myhome} />
                        <Route path='/myhome' exact component={Myhome} />
                        <Route path='/Intermodalshipping' exact component={Intermodalshipping} />
                        <Route path='/AirFreight' exact component={AirFreight} />
                        <Route path='/About' exact component={About} />
                         <Route path='/error-403' exact component={Error403} />
                        <Route path='/error-404' exact component={Error404} />
                        <Route path='/error-405' exact component={Error405} />
                      <Route path='/under-maintenance' exact component={UnderMaintenance} />
                        <Route path='/faqs' exact component={Faqs} />
                        <Route path='/login' exact component={Login} />
                        <Route path='/register' exact component={Register} />
                        <Route path='/contact' exact component={Contact} />
                       <Route path='/short-tabs' exact component={ShortTabs} />
                          <Route path='/short-testimonial' exact component={ShortTestimonial} />
                        <Route path='/short-form' exact component={ShortForm} />
                   	
                
                        
					</Switch>
                </div>
				<ScrollToTop />
				<ThemeButton />
            </BrowserRouter>	
		)
	}
	
}

export default Markup;