import React,{Component} from 'react';
import Header from '../Layout/Header1';
import Footer from '../Layout/Footer1';
import AccordionBlog from '../Element/AccordionBlog';
import FormStyle from '../Element/FormStyle';
import Index4Tab5 from '../Element/Index4Tab5';


//Images

import transportBANNER from './../../images/background/transportBANNER.jpg';
import hk from './../../images/about/hk.png';
import tempcot from './../../images/about/tempcot.png';

import YELLOW from './../../images/about/YELLOW.jpg';


  


class Temperaturecontrolledshipping extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper1">
				<Header />
                 <div className="page-content bg-white">
                 <section className='banner-lti-Temperature'>
                              <div className='container'>
                                  <div className='row'>
                                      <div className='col-md-12'>
                                      <h2 className='banner-heading'>
                                      Temperature Controlled <span> Shipping</span> 
                         </h2>
                                      </div>
                                  </div>
                              </div>
                         
                     </section>
               
                      	{/* <!-- bnner --> */}

                <section className='int-second'>
                    <div className='container'>
                        <div className='row'>
                              <div className='col-md-7'>
                             <div class='at-item' >
        <h2>Understanding The Hurdles Of Temperature-Controlled Shipping</h2>
     <p className='destination'>When shipping cargo internationally, you’ll have a range of shipping methods to choose from. You’ll often want to ensure you choose a cost-efficient 
         option to make the most out of your supply chain. Less-than-Container Load (LCL) shipping is a useful method of shipping in many circumstances, especially 
         when you are transporting small amounts of cargo.</p>
      <br />
      <a href='#' className="site-button">REQUEST A QUOTE </a>
                             </div>
                             </div>
                          
                            <div className='col-md-5'>
                            <div className='sdimg'>
                                <br/>
                          <img src={hk} data-tilt alt=""  className='.slideInUp' / >
                            </div>
                            </div>
                        </div>
            
                </div>
                </section>
          	
			<section className='int-third'>
                <div className='container'>
                    <div className='row'>
                         <div className='col-md-6' id='Transportation'>
                        <img src={YELLOW} data-tilt alt=""  className='.slideInUp' / >
                        </div>
                        <div className='col-md-6' id='Transportation2'>
                   <h2>Intermodal Transportation Services That Increase Your Bottom Line</h2>
                   <div className='leading'>
                   <p>ALS is one of the leading intermodal freight transportation companies offering door-to-door shipping services. Our intermodal shipping services integrate the strengths of the best modes to fuel your supply chain while offering high visibility.</p>

<p>Leveraging cutting-edge technology that provides 24/7 shipment visibility, our intermodal specialists make sure that your freight reaches its destination on time and in a safe manner. We invite you to utilize our experience, knowledge, and expertise to meet your shipping needs.</p>
                   
            </div> 
                   </div>
                    </div>
                </div>
            </section>

            <section className='tabsection'>
                <div className='container'>
                <Index4Tab5/>
                </div>
            </section>


            <section className='air-third'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            <h2>Quick, Reliable, And Eco-Friendly Transportation</h2>
                            <p >When it comes to delivering a shipment in the minimum possible time, air freight is the best solution for you. With transportation 
                                services from ALS, your freight delivery can be as fast as the same day.</p>

                        <p>If you want to transport time-sensitive cargo, such as perishable, or want to transport something valuable or expensive, ALS has got you covered.</p>
                        <ul className="list-angle-right">
                                            <li>An easy-to-use online form to get a personalized quote.</li>
                                            <li>Real-time monitoring of your cargo to keep you updated on your shipment status.</li>
                                            <li>Door-to-door freight delivery services.</li>
                                                                                                                             </ul> 
                                        <br />
                                           <a href='#' className="site-button">REQUEST A QUOTE </a>
                        </div>
                        <div className='col-md-5'>
                        <div className='sdimg'>
                              <div transition-style="in:wipe:right">
                             <img src={tempcot} data-tilt alt=""  className='.slideInUp' / >
                                </div>
                        </div>
                        </div>
                    </div>
                </div>

            </section>
           
            <section className='Hassle'>
                <div className='container'>
                    <div className='row' id='Hassle-Fre'>
                        <div className='col-md-12'>
                              <h2>Highest-Quality Air Freight Shipping Solutions To Enhance Your Supply Chain Efficiencies</h2>
                            <p className='transportation'>Intermodal transportation services offer a plethora of advantages over road alone. Our intermodal specialists combine their experience with 
                                technology to minimize the negative impact on your supply chain besides keeping your freight moving efficiently.</p>
                            <AccordionBlog />
                        </div>
                        </div>
                        </div>
                        </section>
                        <section className='blacksection'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='for-mobile'>
                                            <h2><span>WE’RE HERE TO </span>RESOLVE YOUR BIGGEST<span> LOGISTICS CHALLENGES</span></h2>
                                        </div>
                                    <h2 className="pt-plus-cd-headline letters scale" id='pulse'>
                                        <span>We’re here to </span>
                                        <span className="cd-words-wrapper style-6" style={{width: '1161.44px'}}>
                                        <b className="is-visible " style={{opacity: 1}}>
                                        <div class="waviy">
                                            <span className='s1'>r</span>
                                            <span  className='s2'>e</span>
                                            <span  className='s3'>s</span>
                                            <span  className='s4'>o</span>
                                            <span  className='s5'>l</span>
                                            <span  className='s6'>v</span>
                                            <span  className='s7'>e</span>
                                            </div>
                                            
                                            <div class="waviy1">
                                            <span  className='s8'></span>
                                            <span  className='s9'>y</span>
                                            <span  className='s10'>o</span>
                                            <span  className='s11'>u</span>
                                            <span  className='s12'>r</span>
                                            </div>
                                            <br></br>
                                            <div class="waviy2">
                                            <span  className='s13'>b</span>
                                            <span  className='s14'>i</span>
                                            <span  className='s15'>g</span>
                                            <span  className='s16'>g</span>
                                            <span  className='s17'>e</span>
                                            <span  className='s18'>s</span>
                                            <span  className='s19'>t</span>
                                            
                                                                                    
                                             </div>
                                        </b></span><span>  logistics challenges </span></h2>
                                        <br></br>
                                        <a href='#' className="site-button">REQUEST A QUOTE </a>
                                        
                                    </div>

                                </div>
                            </div>

                        </section>
                       <section className='compre'>
                           <div className='container'>
                               <div className='row'>
                                   <div className='col-md-5'>
                                   <div className="form-banner">
          <h2>Take the Next Step With Us</h2>
          <br></br>
          <form
          id="msform"
          action="fileUploadScript.php"
          method="post"
          encType="multipart/form-data"
        >
      <label>Name</label>
		 <input type="text" name="name" className='form-control' />
     <label>Email</label>
		 <input type="email" name="email" className='form-control' />
     <label>Phone</label>
		 <input type="text" name="phone" className='form-control' />
     <label>Message</label>
		 <textarea name="message" rows="4" className='form-control' > </textarea>
     <input type='submit' value='send' className='form-rol' />
		 </form>
            </div>
                                   </div>
                                   <div className='col-md-7' id='portation'>
                                       <h2>Why Choose ALS For Intermodal Freight Transportation?</h2>
                                       <div className='advantage'>
                                       <p>At ALS, we strive to provide you the flexibility and value. By integrating the strengths of the best modes, we develop a 
                                           multifunctional approach that gets your freight delivered on time. Utilizing the services of a leading intermodal provider 
                                           like ALS allows you to take advantage of cost savings without compromising on your sustainability goals.</p>
                                           <h4>1. Load tracking</h4>
                                           <p>We harness the latest technology to provide you real-time updates about your load. You’ll have access to all the shipping information until your 
                                               freight gets delivered to its final destination.</p>
                                               <h4>2. Reduced handling</h4>
                                               <p>Our intermodal services reduce the frequency of loading and unloading, thus minimizing the chances of loss or damage.</p>
                                               <h4>Dedicated support</h4>
                                               <p>Our intermodal logistics specialist will provide you with dedicated support to make sure that you have a sustainable carrier base.</p>
                                   </div>
                                   </div>
                               </div>
                           </div>
         
            
        </section>
				</div>	
				<Footer />
				
			</div>		
		)
	}
}

export default Temperaturecontrolledshipping;