import React,{Component} from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import OurBlogSlider from './../Element/OurBlogSlider';
import FormStyle from './../Element/FormStyle';
import bgvideo from './../../images/background/bgvideo.mp4';

//Images
import sea from './../../images/about/sea.jpg';
import land from './../../images/about/land.jpg';
import logistics from './../../images/about/logistics.jpg';
import air from './../../images/about/air.jpg';
import airplan from './../../images/about/airplan.png';
import SEAAIRPLAN from './../../images/about/SEAAIRPLAN.jpg';
import carrier from './../../images/about/carrier.jpg';
import CAN from './../../images/about/CAN.jpg';
import carier from './../../images/about/carier.jpg';
import fulltrucks from './../../images/about/fulltrucks.png';
import PngItem from './../../images/about/PngItem.png';
import hk from './../../images/about/hk.png';
import intermodel1 from './../../images/about/intermodel1.png';
import truck1 from './../../images/about/truck1.png';
import truckload from './../../images/about/truckload.png';








class Myhome extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper">
				<Header />
                 <div className="page-content bg-white">
                <section className="bannervideo">
                     <video src={bgvideo}  autoPlay loop muted />
                     <h2>YOUR LOGISTICS PARTNER BEYOND<span>TRUCKLOAD</span></h2>
                      </section>	
                      	 {/* <!-- Second section --> */}	
                    <section className='second-section'>
                    <div className="container">
                                <div className="row ">
                                <div className="col-lg-8 col-md-9">
                                    <div class="Aboutussection">
                                    <h3>ABOUT US</h3>
                                    <h2>ALS is a dedicated team of logistics professionals focused on providing cost-effective and innovative freight transportation 
                                        solutions across air, land, and sea.We’re global players committed to meeting your logistics needs. </h2>
                                </div>
                                </div>
                                <div className="col-lg-4 col-md-3">
                                <div class="Aboutussectionright">
                                <h3>OUR VALUES</h3>
                                <ul>
                                    <li><h4>Commitment</h4></li>
                                    <li><h4>Integrity</h4></li>
                                    <li><h4>Transparency</h4></li>
                                    <li><h4>Safety</h4></li>
                                </ul>
                                    </div>
                                </div>
                               
                                    </div>
                                    </div>
                    </section>

                            {/* <!-- Second section --> */}
					<div className="content-block">
                        {/* <!-- About Us --> */}
                       
                        <section className='myserhome'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-7 col-sm-12'>
                                        <h2>Solutions That Ensure Hassle-Free Shipping Across Global Destinations</h2>
                                    </div>
                                    <div className='col-md-5 col-sm-12'>
                                        <p>ALS has an extensive portfolio of air, sea, and land transportation services that will help you optimize your supply chain
                                             operations. Through our vast network of hubs and shipping partners, we’re able to deliver on-time shipments globally.</p>

                                    </div>
                                </div>

                            </div>

                        </section>
                        
                        {/* <!-- About Us End --> */}
						{/* <!-- Our Services --> */}
                        <div className="section-full content-inner-2">
                            <section className='sea'>
                            <div className="container">
                               <div className='row'>
                                   <div className='col-md-6 col-sm-12' id='reliable'>
                                       <div className='myserbox'>
                                       <img src={sea} data-tilt alt="" />
                                      <div className='myserbox-content'>
                                       <h3>SEA Freight</h3>
                                       <p>We will provide you with a convenient, cost-effective, and reliable solution for your sea freight needs.</p>
                                       </div>
                                       </div>
                                   </div>
                                    <div className='col-md-6 col-sm-12'>
                                    <div className='myserbox'>
                                       <img src={air} data-tilt alt="" />
                                      <div className='myserbox-content'>
                                       <h3>Air Freight</h3>
                                       <p>We make sure your air freight shipment reaches its destination on time and in perfect condition.</p>
                                       </div>
                                       </div>
                                    </div>

                               </div>
                               <br></br>
                               <div className='row'>
                                   <div className='col-md-6 col-sm-12' id='reliable'>
                                       <div className='myserbox'>
                                       <img src={land} data-tilt alt="" />
                                      <div className='myserbox-content'>
                                       <h3>Land Freight</h3>
                                       <p>We will provide you with a convenient, cost-effective, and reliable solution for your sea freight needs.</p>
                                       </div>
                                       </div>
                                   </div>
                                    <div className='col-md-6 col-sm-12'>
                                    <div className='myserbox'>
                                       <img src={logistics} data-tilt alt="" />
                                      <div className='myserbox-content'>
                                       <h3>Project Logistics</h3>
                                       <p>ALS Logistics can handle your project logistics from start to end, taking care of the complexities.</p>
                                       </div>
                                       </div>
                                    </div>

                               </div>
                                  </div>
                            </section>

                        {/* <!-- Our Services --> */}
						{/* <!-- Why Chose Us --> */}
                        <section className='servicesection'>
                            <div className='container'>
                            <div className='row'>
                                    <div className='col-md-9 col-sm-12'>
                                        <h2 className='Ensure'>Our Modes</h2>
                                        <br></br>
                                        <p className='Through'>ALS has an extensive portfolio of air, sea, and land transportation services that will help you optimize your supply chain
                                             operations. Through our vast network of hubs and shipping partners, we’re able to deliver on-time shipments globally.</p>
                                    </div>
                                                                  </div>
                                <br></br>
                               
                                <div className='col-md-12' id='network'>
                                                                                  
                               
                                                                                  <div class="qodef-shortcode qodef-m serivcebox qodef-accordion-info qodef-layout--compact qodef-skin--light qodef--init">
                                                                                  <div class="qodef-m-navigation">
                                                                      <h6 class="qodef-m-tagline">5000 destinations served 	</h6>
                                                                              <div class="qodef-m-title-image-wrapper">
                                                                                  <h3 class="qodef-m-title"><a class="listhome" href="./AirFreight">Air Freight	</a></h3>
                                                                                      <div class="qodef-m-image">
                                                                                      <img src={airplan} data-tilt alt="" />	</div>
                                                                                      </div>
                                                                                          <div class="qodef-m-mark">
                                                          <a class="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link" href="./AirFreight" target="_self">	<span class="qodef-m-text">View Detail</span></a>
                                              
                                                                                      <div class="qodef-m-button">
                                                                                          </div>
                                              <svg class="qodef-svg--navigation-arrow-right qodef-m qodef-m-arrow" xmlns="http://www.w3.org/2000/svg" width="40.385" height="36.77" viewBox="0 0 40.385 36.77"><g class="qodef-arrow-part"><g transform="translate(-1416 -694.115)"><rect class="qodef-m-arrow-part" width="40" height="1" rx="0.5" transform="translate(1416 712)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1456.385 712.5) rotate(135)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1455.678 713.207) rotate(-135)"></rect></g></g><g class="qodef-circle-part"><circle class="qodef-m-circle" cx="6.5" cy="6.5" r="6.5" transform="translate(27 12)"></circle></g></svg>	</div>
                                              </div>
                                              <div class="qodef-m-navigation">
                                                                      <h6 class="qodef-m-tagline">15,000+ carrier network 	</h6>
                                                                              <div class="qodef-m-title-image-wrapper">
                                                                                  <h3 class="qodef-m-title"><a class="listhome" href="./Lessthantruckloadltlshipping">Less than Truckload	</a></h3>
                                                                                      <div class="qodef-m-image">
                                                                                      <img src={fulltrucks} data-tilt alt="" />	</div>
                                                                                      </div>
                                                                                          <div class="qodef-m-mark">
                                                          <a class="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link" href="./Lessthantruckloadltlshipping" target="_self">	<span class="qodef-m-text">View Detail</span></a>
                                              
                                                                                      <div class="qodef-m-button">
                                                                                          </div>
                                              <svg class="qodef-svg--navigation-arrow-right qodef-m qodef-m-arrow" xmlns="http://www.w3.org/2000/svg" width="40.385" height="36.77" viewBox="0 0 40.385 36.77"><g class="qodef-arrow-part"><g transform="translate(-1416 -694.115)"><rect class="qodef-m-arrow-part" width="40" height="1" rx="0.5" transform="translate(1416 712)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1456.385 712.5) rotate(135)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1455.678 713.207) rotate(-135)"></rect></g></g><g class="qodef-circle-part"><circle class="qodef-m-circle" cx="6.5" cy="6.5" r="6.5" transform="translate(27 12)"></circle></g></svg>	</div>
                                              </div>
                                              <div class="qodef-m-navigation">
                                                                      <h6 class="qodef-m-tagline">20,000 loads delivered 	</h6>
                                                                              <div class="qodef-m-title-image-wrapper">
                                                                                  <h3 class="qodef-m-title"><a class="listhome" href="./Flatbedfreight">Flatbed Freight</a></h3>
                                                                                      <div class="qodef-m-image">
                                                                                      <img src={PngItem} data-tilt alt="" />	</div>
                                                                                      </div>
                                                                                          <div class="qodef-m-mark">
                                                          <a class="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link" href="./Flatbedfreight" target="_self">	<span class="qodef-m-text">View Detail</span></a>
                                              
                                                                                      <div class="qodef-m-button">
                                                                                          </div>
                                              <svg class="qodef-svg--navigation-arrow-right qodef-m qodef-m-arrow" xmlns="http://www.w3.org/2000/svg" width="40.385" height="36.77" viewBox="0 0 40.385 36.77"><g class="qodef-arrow-part"><g transform="translate(-1416 -694.115)"><rect class="qodef-m-arrow-part" width="40" height="1" rx="0.5" transform="translate(1416 712)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1456.385 712.5) rotate(135)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1455.678 713.207) rotate(-135)"></rect></g></g><g class="qodef-circle-part"><circle class="qodef-m-circle" cx="6.5" cy="6.5" r="6.5" transform="translate(27 12)"></circle></g></svg>	</div>
                                              </div>
                                              <div class="qodef-m-navigation">
                                                      <h6 class="qodef-m-tagline">
                                                      4000+ loads shipped 	</h6>
                                                  <div class="qodef-m-title-image-wrapper">
                                                          <h3 class="qodef-m-title"><a class="listhome" href="./Temperaturecontrolledshipping">
                                                      Temperature Controlled </a>	</h3>
                                                  <div class="qodef-m-image">
                                                  <img src={hk} data-tilt alt="" />	</div>
                                                  </div>
                                                  <div class="qodef-m-mark">
                                                          <a class="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link" href="./Temperaturecontrolledshipping" target="_self">	<span class="qodef-m-text">View Detail</span></a>
                                              
                                                  <div class="qodef-m-button">
                                                  </div>
                                              <svg class="qodef-svg--navigation-arrow-right qodef-m qodef-m-arrow" xmlns="http://www.w3.org/2000/svg" width="40.385" height="36.77" viewBox="0 0 40.385 36.77"><g class="qodef-arrow-part"><g transform="translate(-1416 -694.115)"><rect class="qodef-m-arrow-part" width="40" height="1" rx="0.5" transform="translate(1416 712)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1456.385 712.5) rotate(135)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1455.678 713.207) rotate(-135)"></rect></g></g><g class="qodef-circle-part"><circle class="qodef-m-circle" cx="6.5" cy="6.5" r="6.5" transform="translate(27 12)"></circle></g></svg>	</div>
                                              </div>
                                              <div class="qodef-m-navigation">
                                                      <h6 class="qodef-m-tagline">
                                                      12000+ tonnes delivered	</h6>
                                                  <div class="qodef-m-title-image-wrapper">
                                                          <h3 class="qodef-m-title"><a class="listhome" href="./Intermodalshipping">
                                                      Intermodal Shipping	</a></h3>
                                                  <div class="qodef-m-image">
                                                  <img src={intermodel1} data-tilt alt="" />	</div>
                                                  </div>
                                                  <div class="qodef-m-mark">
                                                          <a class="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link" href="./Intermodalshipping" target="_self">	<span class="qodef-m-text">View Detail</span></a>
                                              
                                                  <div class="qodef-m-button">
                                                  </div>
                                              <svg class="qodef-svg--navigation-arrow-right qodef-m qodef-m-arrow" xmlns="http://www.w3.org/2000/svg" width="40.385" height="36.77" viewBox="0 0 40.385 36.77"><g class="qodef-arrow-part"><g transform="translate(-1416 -694.115)"><rect class="qodef-m-arrow-part" width="40" height="1" rx="0.5" transform="translate(1416 712)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1456.385 712.5) rotate(135)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1455.678 713.207) rotate(-135)"></rect></g></g><g class="qodef-circle-part"><circle class="qodef-m-circle" cx="6.5" cy="6.5" r="6.5" transform="translate(27 12)"></circle></g></svg>	</div>
                                              </div>
                                              <div class="qodef-m-navigation">
                                                      <h6 class="qodef-m-tagline">
                                                      6000+ FTL loads delivered 	</h6>
                                                  <div class="qodef-m-title-image-wrapper">
                                                          <h3 class="qodef-m-title"><a class="listhome" href="./Fulltruckloadshipping">
                                                      Full Truckload</a>	</h3>
                                                  <div class="qodef-m-image">
                                                      <img src={truck1} data-tilt alt="" />	</div>
                                                  </div>
                                                  <div class="qodef-m-mark">
                                                          <a class="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link" href="./Fulltruckloadshipping" target="_self">	<span class="qodef-m-text">View Detail</span></a>
                                              
                                                  <div class="qodef-m-button">
                                                  </div>
                                              <svg class="qodef-svg--navigation-arrow-right qodef-m qodef-m-arrow" xmlns="http://www.w3.org/2000/svg" width="40.385" height="36.77" viewBox="0 0 40.385 36.77"><g class="qodef-arrow-part"><g transform="translate(-1416 -694.115)"><rect class="qodef-m-arrow-part" width="40" height="1" rx="0.5" transform="translate(1416 712)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1456.385 712.5) rotate(135)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1455.678 713.207) rotate(-135)"></rect></g></g><g class="qodef-circle-part"><circle class="qodef-m-circle" cx="6.5" cy="6.5" r="6.5" transform="translate(27 12)"></circle></g></svg>	</div>
                                              </div>
                                              <div class="qodef-m-navigation">
                                                      <h6 class="qodef-m-tagline">
                                                      5000+ LTC loads delivered 	</h6>
                                                  <div class="qodef-m-title-image-wrapper">
                                                          <h3 class="qodef-m-title"><a class="listhome" href="/Lessthancontainerload">
                                                      Less than Container Load</a>	</h3>
                                                  <div class="qodef-m-image">
                                                  <img src={truckload} data-tilt alt="" />	</div>
                                                  </div>
                                                  <div class="qodef-m-mark">
                                                          <a class="qodef-shortcode qodef-m qodef-m-button qodef-button qodef-layout--textual  qodef-html--link" href="/Lessthancontainerload" target="_self">	<span class="qodef-m-text">View Detail</span></a>
                                              
                                                  <div class="qodef-m-button">
                                                  </div>
                                              <svg class="qodef-svg--navigation-arrow-right qodef-m qodef-m-arrow" xmlns="http://www.w3.org/2000/svg" width="40.385" height="36.77" viewBox="0 0 40.385 36.77"><g class="qodef-arrow-part"><g transform="translate(-1416 -694.115)"><rect class="qodef-m-arrow-part" width="40" height="1" rx="0.5" transform="translate(1416 712)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1456.385 712.5) rotate(135)"></rect><rect class="qodef-m-arrow-part" width="26" height="1" rx="0.5" transform="translate(1455.678 713.207) rotate(-135)"></rect></g></g><g class="qodef-circle-part"><circle class="qodef-m-circle" cx="6.5" cy="6.5" r="6.5" transform="translate(27 12)"></circle></g></svg>	</div>
                                              </div>
                                              </div>
                                                                               
                                                                              </div>
                                </div>

                                
                         
                         </section>
                        
                        
                        {/* <!-- Accelerating your success --> */}

                        <section className='Accelerating'>
                            <div className='container'>
                                
                                     <div className='row'>
                                         <div className='col-md-6 col-sm-12' id='expertise' >
                                             <div className='slideInDown'>
                                             <h2>End-To-End Logistics Solutions</h2>
                                             <p>Utilizing industry-leading technology, industry expertise, and extensive carrier relationships, we strive to provide top-notch logistics 
                                            solutions to our shippers that reduces cost, increases supply chain efficiency, and provides end-to-end visibility.</p>
                                            <ul className="list-angle-right">
                                            <li>Live shipment tracking</li>
                                            <li>Safe freight delivery</li>
                                            <li>24/7 assistance</li>
                                            <li>Expedited shipping </li>
                                            <li>Cutting edge technology</li>
                                        </ul> 
                                         
                                            
                                         </div>
                                                                                  </div>
                                         <div className='col-md-6 col-sm-12' id='expertise2'>
                                      
                                         <img src={SEAAIRPLAN} data-tilt alt="" />
                                             
                                         </div>
                                         </div>
                                         <div className='row'>
                                          <div className='col-md-6 col-sm-12' id='expertise3'>
                                            <img src={carrier} data-tilt alt="" />
                                                 </div>
                                                 <div className='col-md-6 col-sm-12' id='expertise4' >
                                             <div className='slideInDown'>
                                             <h2>Haul For The ALS Carrier Network</h2>
                                             <p>Join the ALS carrier network to minimize your empty miles, and find highest paying loads to grow your business. Our team works round the clock 
                                                 to find loads that match your lane preferences.</p>
                                            <ul className="list-angle-right">
                                            <li>Quality freight</li>
                                            <li>No forced dispatch</li>
                                            <li>Quick financing</li>
                                            <li>Dedicated lanes </li>
                                            <li>Round the clock assistance</li>
                                        </ul> 
                                          
                                            
                                         </div>
                                             </div>
                                         </div>
                            </div>

                        </section>
                         {/* <!-- Accelerating your success --> */}
           
                        {/* <!-- black section home page --> */}
                        <section className='blacksection'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='for-mobile'>
                                            <h2><span>WE’RE HERE TO </span>RESOLVE YOUR BIGGEST<span> LOGISTICS CHALLENGES</span></h2>
                                        </div>
                                    <h2 className="pt-plus-cd-headline letters scale" id='pulse'>
                                        <span>We’re here to </span>
                                        <span className="cd-words-wrapper style-6" style={{width: '1161.44px'}}>
                                        <b className="is-visible " style={{opacity: 1}}>
                                        <div class="waviy">
                                            <span className='s1'>r</span>
                                            <span  className='s2'>e</span>
                                            <span  className='s3'>s</span>
                                            <span  className='s4'>o</span>
                                            <span  className='s5'>l</span>
                                            <span  className='s6'>v</span>
                                            <span  className='s7'>e</span>
                                            </div>
                                            
                                            <div class="waviy1">
                                            <span  className='s8'></span>
                                            <span  className='s9'>y</span>
                                            <span  className='s10'>o</span>
                                            <span  className='s11'>u</span>
                                            <span  className='s12'>r</span>
                                            </div>
                                            <br></br>
                                            <div class="waviy2">
                                            <span  className='s13'>b</span>
                                            <span  className='s14'>i</span>
                                            <span  className='s15'>g</span>
                                            <span  className='s16'>g</span>
                                            <span  className='s17'>e</span>
                                            <span  className='s18'>s</span>
                                            <span  className='s19'>t</span>
                                            
                                                                                    
                                             </div>
                                        </b></span><span>  logistics challenges </span></h2>
                                        <br></br>
                                        <a href='#' className="site-button">REQUEST A QUOTE </a>
                                        
                                    </div>

                                </div>
                            </div>

                        </section>

                         {/* <!-- black section home page --> */}
                                 {/* <!-- DIFFERENCE  section home page --> */}
                                 <section className='DIFFERENCE '>
                                     <div className='container'>
                                         <div className='row'>
                                             <div className='col-md-6'>
                                             <section className='Years'>
                                                 <h2>Dispatch Service</h2>
                                                 <p>We have a profitable fee percent that will increase your profit and improve your results. For more details select the amount of trucks.</p>
                                         <details>
                                            <summary>5+ Years super dispatch</summary>
                                            <p>We are not training dispatchers, we are only hiring experienced dispatchers, who are completing our company targets and complying with our policies.</p>
                                        </details>
                                        <details>
                                            <summary>24/7 Booking loads</summary>
                                            <p>We are not training dispatchers, we are only hiring experienced dispatchers, who are completing our company targets and complying with our policies.</p>
                                        </details>
                                        <details>
                                            <summary>Acheiving targets</summary>
                                            <p>We are not training dispatchers, we are only hiring experienced dispatchers, who are completing our company targets and complying with our policies.</p>
                                        </details>
                                        <details>
                                            <summary>Broker accounts and contacts</summary>
                                            <p>We are not training dispatchers, we are only hiring experienced dispatchers, who are completing our company targets and complying with our policies.</p>
                                        </details>
                                        <details>
                                            <summary>Full money transparency</summary>
                                            <p>We are not training dispatchers, we are only hiring experienced dispatchers, who are completing our company targets and complying with our policies.</p>
                                        </details>
                                        </section>
                                             </div>
                                             <div className='col-md-6' id='Without'>
                                                 <h2>We’ll Help You Get There Faster Without Any Hassle</h2>
                                                 <div className='row' id='technology'>
                                                     
                                                     <div className='col-md-12' id='Responsibly'>
                                                         <h4>Delivering Goods Safely and Responsibly</h4>
                                                         <p> Whether you need an emergency air shipment or want a whole truck or sea container, we can customize transportation solutions 
                                                                to your unique business needs. Whether you need an emergency air shipment or want a whole truck or sea container, we can customize transportation solutions 
                                                                to your unique business needs.</p>
                                                     </div>
                                                 </div>
                                                 <div className='row' id='technology'>
                                                     
                                                     <div className='col-md-12' id='Responsibly'>
                                                         <h4>We Have The Capacity To Serve Every Industry</h4>
                                                            <p> Whether you need an emergency air shipment or want a whole truck or sea container, we can customize transportation solutions 
                                                                to your unique business needs. Whether you need an emergency air shipment or want a whole truck or sea container, we can customize transportation solutions 
                                                                to your unique business needs.</p>
                                                     </div>
                                                 </div>
                                             </div>
                                             
                                         </div>
                                         
                                     </div>

                                 </section>
                                 <section>
                                     <div className='container'>
                                         <div className='row'>
                                             <div className='col-md-6'>
                    

                                             </div>
                                         </div>
                                     </div>
                                 </section>
                                 {/* <!-- DIFFERENCE  section home page --> */}

  
						{/* <!-- Why Chose Us End --> */}
                        	
                        {/* <!-- Blogs --> */}
                        <div className="section-full content-inner bg-img-fix" id='myblog'>
                            <div className="container">
                                <div className="row" id='blog-text'>
                                    <div className="col-md-6">
                                        <h2>Our Blog</h2>
                                                                           </div>
                                    <div className="col-md-6">
                                          <p className="m-b0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
										<OurBlogSlider />
                                    </div>
                                </div>
                            </div>
                        </div>
                          {/* <!-- Blogs --> */}
						{/* <!-- Our Portfolio --> */}
                       
                        
                        </div>
                        {/* <!-- Our Portfolio END --> */}
						
						
											
					</div>
				</div>	
				<Footer />
				
			</div>		
		)
	}
}
export default Myhome;