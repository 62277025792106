import React,{Component} from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';


//Images

import industrial from './../../images/background/industrial.jpg';


import logistics2 from '../../images/logistics2.png';

class Carrier extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper1">
				<Header />
                 <div className="page-content bg-white">
                    <section className='bnner1'>
<div className="container-fluid">
  <div className="row justify-content-center">
    <div class="col-xl-8 col-xxl-8 col-md-10 col-sm-12">
    <div class="card UserAccount">
      <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
      <div className='formimage'>
            <img src={logistics2} data-tilt alt="" />
                        </div>
        
        <h2>Sign Up Your User Account</h2>
        <p>Fill all form field to go to next step</p>
        <form
          id="msform"
          action="mail.php"
          method="post"
          encType="multipart/form-data"
        >
          {/* progressbar */}
        
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>{" "}
          <br /> {/* fieldsets */}
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-xl-7 col-xxl-7 col-md-12  col-sm-12">
                      
                  <h2 className="fs-title">Carrier Sign-up</h2>
                  <p>
                    To Become a Shine Carrier, You Must Meet the Following
                    Criteria:
                  </p>
                  <select name="carrier" id="carrier-option" required>
                  <option value="Reefer">Reefer Carrier</option>
                  <option value="Other">All Other Carrier</option>
                </select>
                </div>
                <div className="col-5" id='steps'>
                  <h2 className="steps">Step 1 - 4</h2>
                </div>
                
              </div>
            </div>
            <input
              type="button"
              name="next"
              className="next action-button"
              defaultValue="Next"
            />
          </fieldset>
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-xl-7 col-xxl-7 col-md-12  col-sm-12">
                  <h2 className="fs-title">Carrier OnBoarding</h2>
                  <h3 classname="Quick">New Carrier Registration</h3>
                  <h4>We Offer 3 days Quick Pay at 3.5%.</h4>
                  <p>
                    Ask for our Truck Dispatch Services. For Carrier Support,
                    Call <a href="#">912-324-678</a>
                  </p>
                </div>
                <div className="col-5" id='steps'>
                  <h2 className="steps">Step 2 - 4</h2>
                </div>
              </div>
            </div>
          
            <input
              type="button"
              name="next"
              className="next action-button"
              defaultValue="Next"
            />
              <input
              type="button"
              name="previous"
              className="previous action-button-previous"
              defaultValue="Previous"
            />
          </fieldset>
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-xl-7 col-xxl-7 col-md-12  col-sm-12">
                  <h2 className="fs-title">Welcome - Carrier Registration Services</h2>
                   <p class="Offer1">
                    To Become a Shine Carrier, You Must Meet the Following
                    Criteria:
                  </p>
                  <p classname="Offer2">
                    Proof of insurance that meets the below requirements:
                  </p>
                  <ul class="listjkjk">
                      <li classname="Operating">
                        Minimum $100,000 cargo insurance
                      </li>
                      <li classname="Operating">
                        Minimum $1,000,000 auto liability insurance
                      </li>
                      <li classname="Operating">
                        Commercial General insurance of $1,000,000 is Preferred
                      </li>
                      <li classname="Operating">
                        Operating authority with the below requirements
                      </li>
                      <li classname="Operating">
                        Must have a active MC or USDOT number
                      </li>
                      <li classname="Operating">
                        Must have been in business a minimum of 3 months
                      </li>
                      <li classname="Operating">
                        Completed W-9 Form for tax purposes
                      </li>
                    </ul>
                </div>
                <div className="col-5" id='steps'>
                  <h2 className="steps">Step 2 - 4</h2>
                </div>
                </div>
              
              
            </div>
           
            <input
              type="button"
              name="next"
              className="next action-button"
              defaultValue="Next"
            />
             <input
              type="button"
              name="previous"
              className="previous action-button-previous"
              defaultValue="Previous"
            />
          </fieldset>
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-xl-7 col-xxl-7 col-md-12  col-sm-12">
                  <h3 class="fs-title">
                    We Offer Driver Dispatch Services at Low Rates.
                  </h3>
                  <p class="Offer1">
                    We have Dedicated Lanes with Top Paying Loads.
                  </p>
          
                </div>
                <div className="col-5" id='steps'>
                  <h2 className="steps">Step 3 - 4</h2>
                </div>
              </div>
              <label className="fieldlabels">MC/DOT Number:*</label>
              <input type="number" name="dotnumber" required />
              <label className="fieldlabels">Company Name:</label>
              <input type="text" name="company" required />
              <label className="fieldlabels">Contact No.: *</label>
              <input type="tel" name="phone" required />
              <label className="fieldlabels">Email: *</label>
              <input type="email" name="email" required />
              <label class="fieldlabels">Upload Resume:</label>
                            <input type="file" name="file" /> 
            </div>
            <div className='final'>
            <input type="submit"  value="Submit" /> 
            </div>
                                </fieldset>
       
        </form>
      </div>
      </div>
    </div>
  </div>
</div>
    </section>
{/* <!-- bnner --> */}
   
				</div>	
				<Footer />
				
			</div>		
		)
	}
}

export default Carrier;