import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li className="active"><Link to={'/'}>Home</Link></li>
					<li><Link to={'/about'}>About Us</Link></li>
									
					<li><Link to={'#'}>Our Service <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
						
						<li><Link to={'./Lessthantruckloadltlshipping'} className="dez-page">Less than truck load ltl shipping</Link></li>
						<li><Link to={'./AirFreight'} className="dez-page">Air Freight</Link></li>
						<li><Link to={'./Fulltruckloadshipping'} className="dez-page">Full Truckload Shipping</Link></li>
						<li><Link to={'./Lessthancontainerload'} className="dez-page">Less than container load</Link></li>
						<li><Link to={'./Temperaturecontrolledshipping'} className="dez-page">Temperature Controlled Shipping</Link></li>
						<li><Link to={'./Flatbedfreight'} className="dez-page">Flatbed Freight</Link></li>
						<li><Link to={'./Intermodalshipping'} className="dez-page">Intermodal Shipping</Link></li>
						<li><Link to={'./Carrier'} className="dez-page">Carrier</Link></li>
					
																		
						</ul>
					</li>
					<li><Link to={'./AllBlogs'}>Blogs</Link></li>
				
					
						<li><Link to={'./contact'} className="dez-page">Contact Us</Link></li>
				</ul>
			</>
		)
	}
}
export default HeaderMenu;