import React,{Component} from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';



//Images

import thik from '../../images/thik.png';




class About extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper">
				<Header />
                 <div className="page-content bg-white">
                 <section className='thankyou'>
           <div className='container'>
            <div className='row'>
            <div className='col-md-12'>
                <div className='thankyoubox'>
                <img src={thik} />
                <h3>Email Invitation Confirmation</h3>
                <p class="updates">Thank you. We are manually evaluating your request to register. Please allow time for processing. <br></br>
                If approved, you will receive an email invitation from <a href="mailto:compliance@dtlines.us">compliance@dtlines.us</a> to <br></br>complete the registration process.</p>
                <hr></hr>
                <h5>THE INVITE TO COMPLETE THE PROCESS WILL BE SENT TO YOUR EMAIL</h5>
                <p class="updates">For updates on your evaluation to onboard, please email <a href="mailto:compliance@dtlines.us">compliance@dtlines.us</a> or call <a href="tel:253-397-0449">253-397-0449</a>. <br></br>
                Again, please allow a few minutes for processing and check your email before <br></br>reaching out for an update.</p>
                </div>
            </div>
            </div>
           </div>
           </section>
                 


  				</div>	
				<Footer />
				
			</div>		
		)
	}
}
export default About;