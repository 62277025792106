import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

import about4 from './../../images/about/pic4.jpg';

import TemperatureControlled from './../../images/about/TemperatureControlled.jpg';


const Index4Tab5= () =>{
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }	
	return(
		<>
			<div className="dez-tabs">
				<div className="m-auto text-center tab-market">
					<ul className="nav nav-tabs">
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '3'}) + ''} onClick={() => { toggle('3'); }}>
															<h5>Unmatched Speed </h5>
							</Link>
						</li>
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '2'}) + ''} onClick={() => { toggle('2'); }}>
							<h5>Unmatched Speed </h5>
							</Link>
						</li>
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '1'}) + ''} onClick={() => { toggle('1'); }}>
							<h5>Unmatched Speed </h5>
							</Link>
						</li>
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '4'}) + ''} onClick={() => { toggle('4'); }}>
							<h5>Unmatched Speed </h5>
							</Link>
						</li>
					</ul>
				</div>
				<div className="tab-content m-t50 text-white">
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<div id="web-design" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-6 m-b30">
										<div className="box">
											<h3><span className="tabspan">Make business with us</span> & become beautiful</h3>
											<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text</p>
									
										</div>
									</div>
									<div className="col-lg-6">
										<img src={TemperatureControlled} className="radius-sm" alt="" />
									</div>
								</div>
							</div>
						</TabPane>	
						<TabPane tabId="2">
							<div id="web-development" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-6 m-b30">
										<img src={TemperatureControlled} className="radius-sm" alt="" />
									</div>
									<div className="col-lg-6">
										<div className="box">
											<h3 className="font-40"><span className="tabspan">Make business with us</span> & become beautiful</h3>
											<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text</p>
										
										</div>
									</div>
								</div>
							</div>
						</TabPane>	
						<TabPane tabId="3">
							<div id="mobile-app" className="tab-pane active show fade">
								<div className="row">
									<div className="col-lg-6 m-b30">
										<div className="box">
										<h3 className="font-40"><span className="tabspan">Make business with us</span> & become beautiful</h3>
											<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text</p>
										
										</div>
									</div>
									<div className="col-lg-6">
										<img src={TemperatureControlled} className="radius-sm" alt="" />
									</div>
								</div>
							</div>
						</TabPane>	
						<TabPane tabId="4">
							<div id="internet-marketing" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-6 m-b30">
										<img src={TemperatureControlled} className="radius-sm" alt="" />
									</div>
									<div className="col-lg-6">
										<div className="box">
											<h3 className="font-40"><span className="tabspan">Make business with us</span> & become beautiful</h3>
											<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
												<p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text</p>
										
										</div>
									</div>
								</div>
							</div>
						</TabPane>	
					</TabContent>		
				</div>
			</div>	
		</>
	)
} 

export default Index4Tab5;