import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';

import bg1 from './../../images/background/bg1.jpg';

class Contact extends Component {

    render() {
        return (
            <>
                <Header />
                <section className='banner-lti-air'>
                              <div className='container'>
                                  <div className='row'>
                                      <div className='col-md-12'>
                                      <h2 className='banner-heading'>
                       Contact <span> Us</span>
                         </h2>
                                      </div>
                                  </div>
                              </div>
                         
                     </section>
                <div className="full-section">
                    {/* <!-- inner page banner --> */}
                  
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-xs-4">
                                    <div className="row text-white">
                                        <div className="col-lg-12 col-md-6 m-b30" >
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                        Company Address
													</h5>
                                                    <p>demo address #8901 Marmora Road Chi Minh City, Vietnam </p>
                                                    <h6 className="m-b15 font-weight-400"><i className="ti-alarm-clock"></i> Office Hours</h6>
                                                    <p className="m-b0">Mon To Sat - 08.00-18.00</p>
                                                    <p>Sunday - Close</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-email"></i></span>
                                                        E-mail
													</h5>
                                                    <p className="m-b0">info@example.com</p>
                                                    <p className="m-b0">sales@example.com</p>
                                                    <p className="m-b0">hr@example.com</p>
                                                    <p>support@example.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-mobile"></i></span>
                                                        Phone Numbers
														</h5>
                                                    <p>+00 0111-123456</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-xs-8">
                           
                                <div className="form-banner">
          <h2>Take the Next Step With Us</h2>
          <br></br>
          <form
          id="msform"
          action="fileUploadScript.php"
          method="post"
          encType="multipart/form-data"
        >
      <label>Name</label>
		 <input type="text" name="name" className='form-control' />
     <label>Email</label>
		 <input type="email" name="email" className='form-control' />
     <label>Phone</label>
		 <input type="text" name="phone" className='form-control' />
     <label>Message</label>
		 <textarea name="message" rows="4" className='form-control' > </textarea>
     <input type='submit' value='send'className='contact' />
		 </form>
            </div>
                                </div>
                            </div>
                        </div>
                   
                    {/* <!-- inner page banner END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default Contact;