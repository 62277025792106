import React,{Component} from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import Index4Tab from './../Element/Index4Tab';

//Images

import industrial from './../../images/background/industrial.jpg';
import plane from './../../images/about/plane.png';
import AIRFREIGHTSHIPPING from './../../images/about/AIRFREIGHTSHIPPING.jpg';
import fg from './../../images/about/fg.jpg';
import log from './../../images/about/log.jpg';



class AirFreight extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper1">
				<Header />
                 <div className="page-content bg-white">
                 <section className='banner-lti-air'>
                              <div className='container'>
                                  <div className='row'>
                                      <div className='col-md-12'>
                                      <h2 className='banner-heading'>
                        Air <span> Freight</span>
                         </h2>
                                      </div>
                                  </div>
                              </div>
                         
                     </section>
                                      	{/* <!-- bnner --> */}

                
          	{/* <!-- airfirst --> */}
              <section className='airfirst'>
                  <div className='container'>
                      <div className='row'>
                          <div className='col-md-8'>
                             <div class='at-item' >
        <h2>Whether You Need Emergency Shipping Services Or Global Freight Transportation Services, We Have The Best Solution For Your Needs.</h2>
      <br />
      <a href='#' className="site-button">REQUEST A QUOTE </a>
                             </div>
                            </div>
                          <div className='col-md-4'>
                          <div className='sdimg'>
                          <img src={plane} data-tilt alt=""  className='.slideInUp' / >
                            </div>
                          
                          </div>
                      </div>
                  </div>

              </section>
              	{/* <!-- airfirst --> */}
              <section className='airsecond'>
                  <div className='container'>
                      <div className='row'>
                          <div className='col-md-6' id='air1'>
                          <div transition-style="in:wipe:down">
                         <img src={AIRFREIGHTSHIPPING} data-tilt alt="" />
                         </div>
                          </div>
                          <div className='col-md-6' id='air2'>
                              <h2>Custom Air Freight Solutions For Your Unique Needs</h2>
                              <p className='fast'>With our fast, reliable air freight services, you can ensure quick delivery of critical, time-sensitive shipments.</p>
                              <ul className="list-angle-right">
                                            <li>Worldwide network across major hubs</li>
                                            <li>Partnership with reliable carriers</li>
                                            <li>Online tracking</li>
                                            <li>Door to door delivery </li>
                                            <li>Cargo insurance</li>
                                        </ul> 
                                           <a href='#' className="site-button">REQUEST A QUOTE </a>
                          </div>
                          
                      </div>
                  </div>
              </section>
			

            <section className='tabsection'>
                <div className='container'>
                <Index4Tab />
                </div>
            </section>


            <section className='air-third'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <h2>Air Freight Shipping Made Easy</h2>
                            <p>When it comes to delivering a shipment in the minimum possible time, air freight is the best solution for you. With transportation 
                                services from ALS, your freight delivery can be as fast as the same day.</p>

                        <p>If you want to transport time-sensitive cargo, such as perishable, or want to transport something valuable or expensive, ALS has got you covered.</p>
                        <ul className="list-angle-right">
                                            <li>An easy-to-use online form to get a personalized quote.</li>
                                            <li>Real-time monitoring of your cargo to keep you updated on your shipment status.</li>
                                            <li>Door-to-door freight delivery services.</li>
                                            <li>Transparent price breakdown to let you know what exactly you’re paying for. </li>
                                            <li>Cost-effective transportation strategies to streamline your logistics portfolio.</li>
                                        </ul> 
                                        <br />
                                           <a href='#' className="site-button">REQUEST A QUOTE </a>
                        </div>
                        <div className='col-md-4'>
                            <div class="Aboutussectionright">
                                <h3>OUR VALUES</h3>
                                <ul>
                                    <li><h4>Commitment</h4></li>
                                    <li><h4>Integrity</h4></li>
                                    <li><h4>Transparency</h4></li>
                                    <li><h4>Safety</h4></li>
                                </ul>
                                </div>
                        </div>
                        
                    </div>
                </div>

            </section>
                                  
                        <section className='landscape1'>
                            <div className='container'>
                    <div className='row'>
                                           <div className='col-md-6' id='Hassle-Free2'>
                                           <div transition-style="in:wipe:right">
                                               <div className='Logistical'>
                            <h2 >Services Designed To Meet Your Complex Logistical Needs</h2>
                            <p>In a constantly evolving logistical landscape, you need a comprehensive solution that combines speed, reliability, and flexibility. ALS offers 
                                a wide gamut of services designed to help you overcome logistical challenges. Choose top-notch solutions to manage your supply chain efficiently.</p>
                                <p>In a constantly evolving logistical landscape, you need a comprehensive solution that combines speed, reliability, and flexibility. ALS offers 
                                a wide gamut of services designed to help you overcome logistical challenges. Choose top-notch solutions to manage your supply chain efficiently.</p>
                            </div>
                            </div>
                            </div>
                        <div className='col-md-6' id='Hassle-Free3'>
                        <img src={fg} data-tilt alt=""  className='.slideInUp' / >
                        </div>
                </div>
                <div className='row'>
                <div className='col-md-6' id='Hassle-Free0'>
                        <img src={log} data-tilt alt=""  className='.slideInUp' / >
                        </div>
                                           <div className='col-md-6' id='Hassle-Free22'>
                                           <div transition-style="in:wipe:right">
                                               <div className='landscape'>
                            <h2 >Services Designed To Meet Your Complex Logistical Needs</h2>
                            <p>In a constantly evolving logistical landscape, you need a comprehensive solution that combines speed, reliability, and flexibility. ALS offers 
                                a wide gamut of services designed to help you overcome logistical challenges. Choose top-notch solutions to manage your supply chain efficiently.</p>
                                <p>In a constantly evolving logistical landscape, you need a comprehensive solution that combines speed, reliability, and flexibility. ALS offers 
                                a wide gamut of services designed to help you overcome logistical challenges. Choose top-notch solutions to manage your supply chain efficiently.</p>
                            </div>
                            </div>
                            

                            </div>
                       
                </div>
                </div>
            </section>
           <section>
               
           </section>
				</div>	
				<Footer />
				
			</div>		
		)
	}
}

export default AirFreight;