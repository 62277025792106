import React, { Component } from 'react';
import Header from '../Layout/Header1';
import Footer from '../Layout/Footer1';


import pic1 from './../../images/about/pic1.jpg';
import Sidebar from '../Element/Sidebar';



class HowtoBeASuccessfulTruckingFleetDispatcher extends Component {

    render() {
        return (
            <>
                <Header />
                <section className='banner-lti-air'>
                              <div className='container'>
                                  <div className='row'>
                                      <div className='col-md-12'>
                                      <h2 className='blogheading'>How to Be A Successful Trucking Fleet Dispatcher?</h2>
                                      </div>
                                  </div>
                              </div>
                         
                     </section>
                <div className="pageblog">
                    <div className="container">
                            <div className="row">
                                <div className='col-lg-8 col-xl-9'>
                        <div className='blog-post blog-single'>
                               
                            <div class="dlab-post-media dlab-img-effect zoom-slow"><a href="/react/demo/blog-details">
                            <img src={pic1} data-tilt alt="" /></a>
                                </div>
                                <br></br>
                                <div className="dlab-post-title "><h2 className="post-title m-t0"><a href="#">How to Be A Successful Trucking Fleet Dispatcher?</a></h2>
                                </div>
                                <br></br>
                                <div class="dlab-post-meta m-b20">
                            <ul class="d-flex align-items-center">
                            <li class="post-date"> <i class="fa fa-calendar"></i><strong>10 Aug</strong> <span> 2016</span> </li>
                            <li class="post-author"><i class="fa fa-user"></i>By<a href="/react/demo/blog-details"> demongo</a> </li>
                            <li class="post-comment"><i class="fa fa-comments"></i><a href="/react/demo/blog-details">0 Comments</a></li>
                            </ul>
                            <hr />
                            
                            </div>
                                <div className='dlab-post-text'>
                                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever 
                                    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum  is simply dummy 
                                    text of the printing and typesetting  printer a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
                                    Ipsum has been the industry's standard text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make 
                                    a type specimen  It has urvived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
                                    It was popularised in 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing 
                                    software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    <hr></hr>
                              
                                    <h3 className='communication'>Employ only one communication channel</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever 
                                    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum  is simply dummy 
                                    text of the printing and typesetting  printer a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
                                    Ipsum has been the industry's standard text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make 
                                    a type specimen  It has urvived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
                                    It was popularised in 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing 
                                    software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    <h3 className='communication'>Be Genuine</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever 
                                    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum  is simply dummy 
                                    text of the printing and typesetting  printer a galley Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem 
                                    Ipsum has been the industry's standard text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make 
                                    a type specimen  It has urvived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
                                    It was popularised in 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing 
                                    software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    <h3 className='communication'>Plan Ahead</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever 
                                    since the 1500s, when an unknown printer took a galley of type and scrambled it</p>
                                </div>
                        </div>
                                </div>
                                <div className='col-lg-4 col-xl-3'>
                               
                            <Sidebar />   
                              </div> 
                                                     
                              
                            </div>
                        </div>
                   
                    {/* <!-- inner page banner END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default HowtoBeASuccessfulTruckingFleetDispatcher;